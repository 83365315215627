import React from 'react';
import { Content } from 'antd/lib/layout/layout';
import {
  Breadcrumb, Layout, Row,
} from 'antd';
import DefaultLoading from '../DefaultLoading';

interface IPageContainer {
  routes: {
    label?: string;
    path?: string;
    Icon?: React.ReactNode;
  }[];
  loading?: boolean;
  children: JSX.Element | JSX.Element[] | React.ReactNode;
}

function PageContainer({
  routes,
  children,
  loading,
}: IPageContainer) {
  return (
    <Layout>
      <Content>
        <Row>
          <Content style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}>
            <Breadcrumb style={{ width: '100%' }}>
              {routes.map(({ Icon, label, path }) => (
                <Breadcrumb.Item
                  key={`item-${label}-${path || ''}`}
                  {...path ? { href: path } : {}}
                >
                  {Icon}
                  {!!label && <span>{label}</span>}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Content>
        </Row>

        <Row>
          <Content style={{ padding: 24 }}>
            {loading ? <DefaultLoading /> : children}
          </Content>
        </Row>
      </Content>
    </Layout>
  );
}

PageContainer.defaultProps = {
  loading: false,
};

export default PageContainer;
