import * as Sentry from '@sentry/react';

export interface ILocalStorageKeys {
  'Auth:Token': string;
  'Auth:Email': string;
}

type TStorageKey = keyof ILocalStorageKeys;

export function getLocalStorageItem<K extends TStorageKey>(key: K): ILocalStorageKeys[K] | null {
  try {
    const res = localStorage.getItem(key);

    return res ? JSON.parse(res) : null;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra('key', key);
      Sentry.captureException(err);
    });

    return null;
  }
}

export function setLocalStorageItem<K extends TStorageKey>(key: K, val: ILocalStorageKeys[K]) {
  try {
    localStorage.setItem(key, JSON.stringify(val));

    return true;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra('key', key);
      scope.setExtra('value', val);
      Sentry.captureException(err);
    });

    return false;
  }
}

export function removeLocalStorageItem<K extends TStorageKey>(key: K) {
  try {
    localStorage.removeItem(key);

    return true;
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtra('key', key);
      Sentry.captureException(err);
    });

    return false;
  }
}
