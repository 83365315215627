import React from 'react';
import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Header } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

const items = [
  {
    title: 'Geral',
    slug: 'geral',
    icon: null,
    subitems: [
      {
        title: 'Candidatos',
        slug: 'candidatos',
      },
      {
        title: 'Métricas',
        slug: 'metricas',
      },
      {
        title: 'Empresas',
        slug: 'empresas',
      },
      {
        title: 'Recrutadores',
        slug: 'recrutadores',
      },
      {
        title: 'Aplicações (Geral)',
        slug: 'aplicacoes-geral',
      },
      {
        title: 'Aplicações (Por vaga)',
        slug: 'aplicacoes',
      },
    ],
  },
];

function SidebarMenu() {
  return (
    <Sider
      width={275}
      className="SidebarMenu"
      breakpoint="lg"
      collapsedWidth="0"
    >
      <Link to="/" title="NewSubscriptionCallback">
        <Header className="logo d-flex justify-content-center align-items-center my-3">
          <img
            src="/images/logo-white.png"
            style={{ maxWidth: '145px' }}
            alt="Logo"
          />
        </Header>
      </Link>

      <Menu
        mode="inline"
        theme="dark"
        forceSubMenuRender
        selectedKeys={['geral']}
        defaultOpenKeys={['geral']}
      >
        {items.map((item) => (item.subitems.length ? (
          <SubMenu key={item.slug} title={item.title} icon={item.icon}>
            {item.subitems.map((subitem) => (
              <Menu.Item key={`${item.slug}_${subitem.slug}`}>
                <Link title={subitem.title} to={`/${subitem.slug}`}>
                  {subitem.title}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={item.slug} icon={item.icon}>
            <Link title={item.title} to={`/${item.slug}`}>{item.title}</Link>
          </Menu.Item>
        )))}
      </Menu>
    </Sider>
  );
}

export default React.memo(SidebarMenu);
