import React, { useCallback, useEffect, useState } from 'react';
import {
  HomeOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Button, message, Space } from 'antd';
import {
  Col, Row, Select,
} from 'antd/lib';
import {
  ApplicationFilterInput,
  useApplicationsLazyQuery,
  useExportFilteredApplicationsDataMutation,
  usePositionsQuery,
} from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';

import { useApplicationReview } from './useApplicationReview';
import { TableApplicationsByPosition } from './components/TableApplicationsByPosition';
import { FilterPositionByStatus } from './components/FilterPositionByStatus';
import { FilterPositionByMinorities } from './components/FilterPositionByMinorities';
import { useBaseStore } from '../../Stores/useBaseStore';

function ApplicationsList() {
  const { techSkills } = useBaseStore(['techSkills']);
  const [positionId, setPositionId] = useState('');
  const [filter, setFilter] = useState<ApplicationFilterInput>({
    applicationStatus: null,
    recruiterStatus: null,
    raceId: null,
    sexualOrientationId: null,
    genderId: null,
    techSkills: [],
  });

  const { loading: loadingPositions, data: dataPositions } = usePositionsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [doLoadApplications, { loading, data, refetch }] = useApplicationsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { positionId, filter },
  });

  const [doExportData, { loading: loadingExport }] = useExportFilteredApplicationsDataMutation({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { positionId, filter },
  });

  const onExportData = useCallback(async () => {
    try {
      if (loadingExport) return;

      const { data: res } = await doExportData();

      if (!res?.exportFilteredApplicationsData) {
        throw new Error('Ocorreu um erro ao exportar os dados.');
      }

      window.open(res?.exportFilteredApplicationsData, '_blank');
    } catch (err) {
      message.error(err.message);
    }
  }, [doExportData, loadingExport]);

  useEffect(() => {
    if (positionId) {
      doLoadApplications();
      setFilter({ applicationStatus: null, recruiterStatus: null });
    }
  }, [positionId]);

  const { onDeny, onApprove, loading: loadingReview } = useApplicationReview(refetch);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Aplicações (Por vaga)' },
      ]}
    >
      <Row className="mb-3">
        <Space align="center" style={{ alignItems: 'center', display: 'flex' }}>
          <Title level={5} style={{ margin: 0 }}>Aplicações (Por vaga)</Title>
        </Space>
      </Row>

      <Row className="mb-3">
        <Col>
          <p>Selecione uma vaga para continuar:</p>

          <Select
            allowClear
            showSearch
            loading={loadingPositions}
            optionFilterProp="children"
            style={{ minWidth: 300 }}
            placeholder="Selecione"
            size="middle"
            filterOption={(input, opt) => (
              (opt!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            )}
            onChange={(val) => setPositionId(val)}
          >
            <Select.Option value="">Selecione</Select.Option>
            {dataPositions?.positions.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>

          {!!positionId && (
            <Button
              type="primary"
              htmlType="button"
              className="ml-4"
              loading={loadingExport}
              onClick={onExportData}
            >
              Exportar em CSV
            </Button>
          )}
        </Col>
      </Row>

      {!!positionId && (
        <>
          <Row>
            <Col xs={24} md={7} className="pr-1">
              <FilterPositionByStatus filters={filter} setFilter={setFilter} />
            </Col>

            <Col xs={24} md={12} className="pl-1 pr-1">
              <FilterPositionByMinorities filters={filter} setFilter={setFilter} />
            </Col>

            <Col xs={24} md={5} className="pl-1">
              <p>Habilidade técnica</p>

              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecione"
                size="middle"
                onChange={(val) => {
                  setFilter({ ...filter, techSkills: (val || []).map(Number) });
                }}
                filterOption={(input, opt) => (
                  (opt!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                )}
                mode="multiple"
              >
                <Select.Option value="">Selecione</Select.Option>
                {techSkills.map((opt) => (
                  <Select.Option key={`opts-${opt.id}`} value={opt.id}>
                    {opt.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          {!(loading || loadingReview) && (
            <Row>
              <p>
                {`${data?.applications?.length} resultados encontrados`}
              </p>
            </Row>
          )}

          <TableApplicationsByPosition
            data={data}
            loading={loading || loadingReview}
            onApprove={onApprove}
            onDeny={onDeny}
          />
        </>
      )}
    </PageContainer>
  );
}

export default ApplicationsList;
