import React, { useCallback } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Modal } from 'antd/lib';
import { useReviewApplicationMutation } from '../../Graphql/generated';

const { confirm } = Modal;

export function useApplicationReview(onRefetch: () => void) {
  const [doReviewApplication, { loading: loadingReview }] = useReviewApplicationMutation({
    notifyOnNetworkStatusChange: true,
  });

  const onDeny = useCallback((applicationId: string) => {
    try {
      confirm({
        title: 'Tem certeza que deseja reprovar essa candidatura?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          doReviewApplication({
            variables: {
              input: {
                applicationId,
                approved: false,
                info: '',
              },
            },
          })
            .then(() => onRefetch());
        },
        onCancel() {},
      });
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [doReviewApplication, onRefetch]);

  const onApprove = useCallback((applicationId: string) => {
    try {
      confirm({
        title: 'Tem certeza que deseja aprovar esse candidato?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          doReviewApplication({
            variables: {
              input: {
                applicationId,
                approved: true,
              },
            },
          })
            .then(() => onRefetch());
        },
        onCancel() {},
      });
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [doReviewApplication, onRefetch]);

  return {
    loading: loadingReview,
    onApprove,
    onDeny,
  };
}
