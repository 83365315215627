import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PageLogin from '../Pages/Login';
import ProtectRoute from '../Components/Atoms/ProtectRoute';
import LoggedInRouter from './LoggedInRouter';
import { useAuthenticationStore } from '../Stores/useAuthenticationStore';
import DefaultLoading from '../Components/Atoms/DefaultLoading';

function LoggedOutRouter() {
  const { initialized, onInit, loggedIn } = useAuthenticationStore(['initialized', 'onInit', 'loggedIn']);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return initialized ? (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={(
              <ProtectRoute condition={!loggedIn} redirect="/">
                <PageLogin />
              </ProtectRoute>
            )}
          />

          <Route
            path="/*"
            element={(
              <ProtectRoute condition={loggedIn} redirect="/login">
                <LoggedInRouter />
              </ProtectRoute>
            )}
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  ) : (
    <DefaultLoading />
  );
}

export default LoggedOutRouter;
