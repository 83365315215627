import React from 'react';
import { Row, Spin } from 'antd';

function DefaultLoading() {
  return (
    <Row align="middle" justify="center" className="py-10">
      <Spin size="large" tip="Carregando base..." />
    </Row>
  );
}

export default DefaultLoading;
