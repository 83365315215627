import React from 'react';
import { Navigate } from 'react-router-dom';

type ProtectRouteProps = {
  condition: boolean;
  redirect: string;
  children: JSX.Element;
};

function ProtectRoute({
  condition,
  redirect,
  children,
}: ProtectRouteProps) {
  return condition
    ? children
    : <Navigate to={{ pathname: redirect }} />;
}

export default ProtectRoute;
