import { create } from 'zustand';
import { createZustandStoreWithSelectors } from '../Utils/createZustandWithSelectors';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../Utils/localStorageProvider';

export interface IAuthenticationStore {
  token: string;
  email: string;
  loggedIn: boolean;
  initialized: boolean;
  onInit: () => void;
  onSignIn: (token: string, email: string) => void;
  onSignOut: () => void;
}

const authenticationStore = create<IAuthenticationStore>((set) => ({
  token: '',
  email: '',
  loggedIn: false,
  initialized: false,
  onSignIn: (token, email) => {
    setLocalStorageItem('Auth:Token', token);
    setLocalStorageItem('Auth:Email', email);

    set(() => ({ token, email, loggedIn: true }));
  },
  onInit: () => {
    const token = getLocalStorageItem('Auth:Token') || '';
    const email = getLocalStorageItem('Auth:Email') || '';

    set(() => ({
      token,
      email,
      loggedIn: !!(token && email),
      initialized: true,
    }));
  },
  onSignOut: () => {
    removeLocalStorageItem('Auth:Token');
    removeLocalStorageItem('Auth:Email');

    set(() => ({ token: '', email: '', loggedIn: false }));
  },
}));

export const useAuthenticationStore = createZustandStoreWithSelectors(authenticationStore);
