import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { Button, Table, Tooltip } from 'antd/lib';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { getColor } from '../helpers';
import { formatDateLuxon } from '../../../Utils/formatDateLuxon';
import { AppRouterEnum } from '../../../Types/Enums/AppRouter.enum';
import { ApplicationsQuery } from '../../../Graphql/generated';

interface ITableApplicationsByPosition {
  loading: boolean;
  data?: ApplicationsQuery;
  onApprove: (id: string) => void;
  onDeny: (id: string) => void;
}

export function TableApplicationsByPosition({
  loading,
  data,
  onApprove,
  onDeny,
} : ITableApplicationsByPosition) {
  const getStars = (rating: number) => {
    if (rating === 1) return '★☆☆☆☆';
    if (rating === 2) return '★★☆☆☆';
    if (rating === 3) return '★★★☆☆';
    if (rating === 4) return '★★★★☆';
    return '★★★★★';
  };

  return (
    <Table
      loading={loading}
      onRow={(record) => ({
        onClick: (ev) => {
          // @ts-ignore
          const target = (ev.target?.tagName || '').toLowerCase();

          if (!['a', 'svg', 'button'].includes(target)) {
            window.open(`${AppRouterEnum.CANDIDATES_PREFIX}/${record.candidateId}`);
          }
        },
      })}
      className="table-clickable-row"
      columns={[
        {
          title: 'Nome do candidato',
          dataIndex: 'candidateName',
          key: 'candidateName',
          sorter: (a, b) => a.candidateName.localeCompare(b.candidateName),
        }, {
          title: 'Skills Técnicas',
          dataIndex: 'techSkills',
          key: 'techSkills',
          width: 280,
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          render: (_, record) => {
            const items = record.techSkills.map((item) => ({
              ...item,
              labelFormatted: item.label.length > 27 ? `${item.label.substring(0, 27)}...` : item.label,
              rating: getStars(item.rating),
            }));

            return (
              <div>
                {items.map((item) => (
                  <Tooltip title={item.label} key={`record-${record.candidateId}-${item.id}`}>
                    <p style={{ display: 'block', width: 280 }}>{`${item.labelFormatted} ${item.rating}`}</p>
                  </Tooltip>
                ))}
              </div>
            );
          },
        }, {
          title: 'Empresa',
          dataIndex: 'empresa',
          key: 'empresa',
          sorter: (a, b) => a.companyName.localeCompare(b.companyName),
          render: (_, record) => (
            <p className="m-0">
              {record.companyName.length > 15 ? `${record.companyName.substring(0, 15)}...` : record.companyName}
            </p>
          ),
        }, {
          title: 'Data de aplicação',
          dataIndex: 'appliedAt',
          key: 'appliedAt',
          render: (_, record) => <p className="m-0">{formatDateLuxon(record.appliedAt)}</p>,
        }, {
          title: 'Status Timbre',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status.localeCompare(b.status),
          render: (_, record) => (
            <p
              className="m-0"
              style={{ color: getColor(record.status) }}
            >
              {record.status}
            </p>
          ),
        }, {
          title: 'Status Recrutador',
          dataIndex: 'recruiterStatus',
          key: 'recruiterStatus',
          sorter: (a, b) => a.recruiterStatus.localeCompare(b.recruiterStatus),
          render: (_, record) => (
            <p
              className="m-0"
              style={{ color: getColor(record.recruiterStatus) }}
            >
              {record.recruiterStatus}
            </p>
          ),
        }, {
          title: 'Ações',
          key: 'acoes',
          render: (_, record) => (
            <Space size="middle">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                to={`${AppRouterEnum.CANDIDATES_PREFIX}/${record.candidateId}`}
                title={`Visualizar candidato ${record.candidateName}`}
                target="_blank"
              >
                <Button icon={<UserOutlined />} shape="circle" htmlType="button" />
              </Link>

              <Link
                to={`${process.env.REACT_APP_SITE_CANDIDATO}/vagas/${record.positionId}`}
                title={`Visualizar vaga ${record.candidateName}`}
                target="_blank"
              >
                <Button icon={<InfoCircleOutlined />} shape="circle" />
              </Link>

              {record.status === 'Pendente' && (
                <>
                  -

                  <Button
                    icon={<CheckCircleOutlined style={{ color: 'green' }} />}
                    shape="circle"
                    onClick={() => onApprove(record.id)}
                  />

                  <Button
                    icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                    shape="circle"
                    onClick={() => onDeny(record.id)}
                  />
                </>
              )}
            </Space>
          ),
        },
      ]}
      rowKey={(record) => record.id}
      pagination={{
        pageSize: 10,
        defaultCurrent: 1,
        total: data?.applications.length,
        pageSizeOptions: [5, 10, 25, 50, 100],
      }}
      dataSource={data?.applications || []}
      locale={{ emptyText: 'Nenhuma aplicação encontrada' }}
    />
  );
}
