import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import PageHome from '../Pages/Home';
import CandidatesList from '../Pages/Candidatos/List';
import CandidatesView from '../Pages/Candidatos/View';
import SidebarMenu from '../Components/Organisms/SidebarMenu';
import { AppRouterEnum } from '../Types/Enums/AppRouter.enum';
import { useAuthenticationStore } from '../Stores/useAuthenticationStore';
import { useBaseStore } from '../Stores/useBaseStore';
import CompaniesList from '../Pages/Company/List';
import CompanyForm from '../Pages/Company/Form';
import RecruitersList from '../Pages/Recruiters/List';
import RecruiterForm from '../Pages/Recruiters/Form';
import ApplicationsGeneralList from '../Pages/Applications/ListAll';
import ApplicationsList from '../Pages/Applications/ListByPosition';
import MetricsList from '../Pages/Metrics/List';

function LoggedInRouter() {
  const { email, onSignOut } = useAuthenticationStore(['email', 'onSignOut']);
  const { initialized, onLoad } = useBaseStore(['onLoad', 'initialized']);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return initialized ? (
    <Layout style={{ minHeight: '100vh' }}>
      <SidebarMenu />

      <Layout>
        <Header className="px-3">
          <div className="div text-right">
            <Dropdown
              className="ml-3"
              overlay={(
                <Menu>
                  <Menu.Item disabled>
                    <span>{email}</span>
                  </Menu.Item>

                  <Menu.Item danger onClick={onSignOut}>
                    <span>Logout</span>
                  </Menu.Item>
                </Menu>
              )}
            >
              <Avatar
                size={35}
                icon={<UserOutlined />}
                style={{ cursor: 'pointer' }}
              />
            </Dropdown>
          </div>
        </Header>

        <Content>
          <Routes>
            <Route path="/" element={<PageHome />} />

            <Route path={AppRouterEnum.CANDIDATES_PREFIX} element={<CandidatesList />} />
            <Route path={`${AppRouterEnum.CANDIDATES_PREFIX}/:id`} element={<CandidatesView />} />

            <Route path={AppRouterEnum.METRICS_PREFIX} element={<MetricsList />} />

            <Route path={AppRouterEnum.COMPANIES_PREFIX} element={<CompaniesList />} />
            <Route path={`${AppRouterEnum.COMPANIES_PREFIX}/:id`} element={<CompanyForm />} />

            <Route path={AppRouterEnum.RECRUITERS_PREFIX} element={<RecruitersList />} />
            <Route path={`${AppRouterEnum.RECRUITERS_PREFIX}/:id`} element={<RecruiterForm />} />

            <Route
              path={AppRouterEnum.APPLICATIONS_GENERAL_PREFIX}
              element={<ApplicationsGeneralList />}
            />

            <Route path={AppRouterEnum.APPLICATIONS_PREFIX} element={<ApplicationsList />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  ) : <div />;
}

export default LoggedInRouter;
