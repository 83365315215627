import React, { useCallback, useMemo } from 'react';
import { ExportOutlined, HomeOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  Button, Space, Table,
} from 'antd';
import { message, Row } from 'antd/lib';
import {
  MetricsOptionEnum,
  useExportMetricsMutation,
  useMetricsQuery,
} from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';

function MetricsList() {
  const { loading, data } = useMetricsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [doExportMetrics, { loading: loadingExport }] = useExportMetricsMutation({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const onExportData = useCallback(async (type: MetricsOptionEnum) => {
    try {
      if (loadingExport) return;

      const { data: res } = await doExportMetrics({
        variables: {
          input: { type },
        },
      });

      if (!res?.exportMetrics) {
        throw new Error('Ocorreu um erro ao exportar os dados.');
      }

      window.open(res?.exportMetrics, '_blank');
    } catch (err) {
      message.error(err.message);
    }
  }, [doExportMetrics, loadingExport]);

  const items = useMemo(() => {
    if (!data?.metrics) {
      return [];
    }

    return [
      {
        id: 'totalUsers',
        title: 'Total de usuários',
        value: data.metrics.totalUsers,
        type: MetricsOptionEnum.TotalUsers,
      },
      {
        id: 'candidatesWithSelfDeclaration',
        title: 'Auto-declaração preenchida',
        value: data.metrics.candidatesWithSelfDeclaration,
        type: MetricsOptionEnum.WithSelfDeclaration,
      },
      {
        id: 'candidatesWithoutSelfDeclaration',
        title: 'Auto-declaração NÃO preenchida',
        value: data.metrics.candidatesWithoutSelfDeclaration,
        type: MetricsOptionEnum.WithoutSelfDeclaration,
      },
      {
        id: 'candidatesWithBehavioralV2',
        title: 'ComportamentoV2 preenchido',
        value: data.metrics.candidatesWithBehavioralV2,
        type: MetricsOptionEnum.WithBehavioralV2,
      },
      {
        id: 'candidatesWithoutBehavioralV2',
        title: 'ComportamentoV2 NÃO preenchido',
        value: data.metrics.candidatesWithoutBehavioralV2,
        type: MetricsOptionEnum.WithoutBehavioralV2,
      },
      {
        id: 'candidatesWithAudio',
        title: 'Áudios enviados',
        value: data.metrics.candidatesWithAudio,
        type: MetricsOptionEnum.WithAudio,
      },
      {
        id: 'candidatesWithoutAudio',
        title: 'Áudios NÃO enviados',
        value: data.metrics.candidatesWithoutAudio,
        type: MetricsOptionEnum.WithoutAudio,
      },
    ];
  }, [data?.metrics]);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Métricas' },
      ]}
    >
      <Row className="mb-3">
        <Space align="center">
          <Title level={5} style={{ margin: 0 }}>Métricas</Title>
        </Space>
      </Row>

      <Table
        loading={loading}
        columns={[
          {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
            width: 400,
          }, {
            title: 'Quantidade',
            dataIndex: 'value',
            key: 'value',
            width: 130,
            sorter: (a, b) => a.value - b.value,
          }, {
            title: 'Ações',
            key: 'acoes',
            width: 130,
            render: (_, record) => (
              <Space size="middle">
                <Button
                  icon={<ExportOutlined />}
                  htmlType="button"
                  type="primary"
                  loading={loadingExport}
                  onClick={() => onExportData(record.type)}
                >
                  Exportar e-mails
                </Button>
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record.title}
        dataSource={items || []}
        locale={{ emptyText: 'Nenhuma métrica encontrada' }}
      />
    </PageContainer>
  );
}

export default MetricsList;
