import { create } from 'zustand';
import { message } from 'antd';
import { createZustandStoreWithSelectors } from '../Utils/createZustandWithSelectors';
import {
  BaseDocument,
  BaseQuery,
  BaseQueryVariables,
  OptionOutput,
  OptionsOutput,
  ProfessionalCategoryEnum,
} from '../Graphql/generated';
import { createApolloClient } from '../Base/apollo';

type TQuestionsKey = 'feelingComfortable'
| 'easeNewKnowledge'
| 'creativeSolutions'
| 'leadingTeamsOrProjects'
| 'manageHighPressureSituations'
| 'understandNecessities'
| 'comfortableNegotiating'
| 'moreThanFiveYears'
| 'communicateComplexInfo'
| 'professionalCategory'
| 'howKnowTimbre'
| 'state'
| 'ageRange'
| 'educationLevel'
| 'gender'
| 'sexualOrientation'
| 'disabledPerson'
| 'race'
| 'jobType'
| 'askAboutUnknownThings'
| 'problemSolvingInitiative'
| 'learnSkillsOrConcepts'
| 'collaborateWithColleagues'
| 'takingResponsabilityForMistakes'
| 'activelySeekForLearning'
| 'motivationInArea'
| 'botherWhenTechnicalKnowledgeIsChallenged'
| 'notCalmyInStressfullSituation'
| 'helpTeammatesInTrouble'
| 'solveProblemsEasily'
| 'waitTheAskForHelp'
| 'prioritizeSimultaneousTasks'
| 'explainWithDidactic'
| 'followYourIdeas'
| 'experienceTime'
| 'botherWhenKnowledgeIsChallenged'
| 'likeHardGoals'
| 'askForHelpInOurProfessionalNetworkRelation'
| 'manageProjectsNeedsResilience'
| 'askAndGiveFeedbacks'
| 'checkComplexInfo'
| 'difficultWithNewTasks'
| 'difficultAdaptCommunication';

export interface IBaseStore {
  initialized: boolean;
  questions: BaseQuery['base']['questions'];
  options: BaseQuery['base']['options'];
  techSkills: BaseQuery['base']['techSkills'];
  onLoad: () => Promise<void>;
  getOptionValue: (key: keyof Omit<OptionsOutput, '__typename'>, value?: number | null) => string;
  getQuestionTitle: (key: TQuestionsKey) => string;
  getProfessionalCategoryEnumById: (id: number) => ProfessionalCategoryEnum | null;
}

const baseStore = create<IBaseStore>((set, getState) => ({
  initialized: false,
  options: {
    howKnowUs: [],
    ageRange: [],
    gender: [],
    likert: [],
    disabledPerson: [],
    educationLevel: [],
    race: [],
    experienceTime: [],
    states: [],
    professionalCategory: [],
    sexualOrientation: [],
  },
  techSkills: [],
  questions: [],
  onLoad: async () => {
    try {
      const client = createApolloClient();

      const { data: baseData } = await client.query<BaseQuery, BaseQueryVariables>({
        query: BaseDocument,
        fetchPolicy: 'no-cache',
      });

      if (
        !baseData?.base?.questions
        || !baseData.base.options
      ) {
        throw new Error('Ocorreu um erro.');
      }

      set(() => ({
        questions: baseData.base.questions,
        options: baseData.base.options,
        techSkills: baseData.base.techSkills,
        initialized: true,
      }));
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro ao carregar o projeto');
    }
  },
  getOptionValue: (key, value): string => {
    const arr = (getState().options[key] || []) as unknown as OptionOutput[];
    const val = arr.find((item) => item.id === `${value}`);

    return val?.label || '-';
  },
  getQuestionTitle(key): string {
    const opt = getState().questions.find((item) => item.key === key);

    return (opt?.text || key).replace('*', '').trim();
  },
  getProfessionalCategoryEnumById(id): ProfessionalCategoryEnum | null {
    const item = getState().options.professionalCategory.find((opt) => Number(opt.id) === id);

    return item?.enumValue || null;
  },
}));

export const useBaseStore = createZustandStoreWithSelectors(baseStore);
