import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col, Form, Input, message, Row,
} from 'antd/lib';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import PageContainer from '../../Components/Atoms/PageContainer';
import DefaultLoading from '../../Components/Atoms/DefaultLoading';
import { useGetCompanyLazyQuery, useUpsertCompanyMutation } from '../../Graphql/generated';

interface IModel {
  name: string;
  active: boolean;
}

function CompanyForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [$form] = Form.useForm<IModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const isEdit = useMemo(() => id !== 'criar', [id]);

  const [model, setModel] = useState<IModel>({
    name: '',
    active: true,
  });

  const [doLoadCompany, { loading, error }] = useGetCompanyLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { id: id! },
  });

  const [onSaveCompany, { loading: loadingSave }] = useUpsertCompanyMutation({
    notifyOnNetworkStatusChange: true,
  });

  const onLoad = useCallback(async () => {
    try {
      const { data } = await doLoadCompany();

      setModel({
        name: data?.company?.name || '',
        active: data?.company?.active || false,
      });

      setIsInitialized(true);
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [doLoadCompany]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = $form.getFieldsValue();

      await onSaveCompany({
        variables: {
          input: {
            id: isEdit ? id : null,
            name: formValues.name,
            active: formValues.active,
          },
        },
      });

      message.success('Empresa salva com sucesso.');
      navigate(AppRouterEnum.COMPANIES_PREFIX);
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [$form, id, isEdit, onSaveCompany, navigate]);

  useEffect(() => {
    if (isEdit) onLoad();
    else setIsInitialized(true);
  }, [isEdit]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message);
      navigate(AppRouterEnum.COMPANIES_PREFIX);
    }
  }, [error]);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { path: AppRouterEnum.COMPANIES_PREFIX, label: 'Empresas' },
        { label: 'Visualizar' },
      ]}
      loading={loading}
    >
      {loading || !isInitialized ? (
        <DefaultLoading />
      ) : (
        <Form
          form={$form}
          layout="vertical"
          initialValues={model}
          onFinish={onSubmit}
        >
          <Row>
            <Col xs={24} md={10}>
              <Form.Item
                name="name"
                className="mb-1"
                label="Nome"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  style={{ minWidth: 200 }}
                  placeholder="Nome da empresa"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={1} />

            <Col xs={24} md={10}>
              <Form.Item
                name="active"
                label="Ativo"
                className="mb-3"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row justify="end">
                <Form.Item>
                  <Button type="primary" loading={loadingSave} htmlType="submit">Salvar</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </PageContainer>
  );
}

export default CompanyForm;
