import React, { FC } from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

type IsActiveTagProps = {
  isActive: boolean
};

const IsActiveTag: FC<IsActiveTagProps> = ({ isActive }) => (
  isActive ? (<CheckCircleTwoTone twoToneColor="#52c41a" />) : (<CloseCircleTwoTone twoToneColor="#ff4d4f" />)
);

export default IsActiveTag;
