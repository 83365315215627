import React, { useCallback, useMemo } from 'react';
import { Button, Descriptions, message } from 'antd';
import './AudioDetail.css';
import { Tooltip } from 'antd/lib';
import { InfoCircleFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  CandidateDetailAudioOutput,
  useCandidateSyncAnalysisMutation,
  useCandidateTranscriptionLazyQuery,
} from '../../../../../Graphql/generated';

interface IAudioDetail {
  audio: CandidateDetailAudioOutput;
  email: string;
  hasAnalysis: boolean;
  onReload: () => void;
}

function AudioDetail({
  audio,
  email,
  onReload,
  hasAnalysis,
}: IAudioDetail) {
  const [doLoadTranscription, { loading: loadingTexts }] = useCandidateTranscriptionLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [doLoadAnalysis, { loading: loadingAnalysis }] = useCandidateSyncAnalysisMutation({
    variables: {
      input: {
        email,
        textExperiences: audio.audioExperiencesTranscription || audio.audioExperiencesText || '',
        textRoutine: audio.audioRoutineTranscription || audio.audioRoutineText || '',
      },
    },
  });

  const onGenerateAudioTranscription = useCallback(async () => {
    try {
      if (!audio.audioRoutinePath || !audio.audioExperiencesPath) {
        return;
      }

      if (loadingTexts) return;

      await doLoadTranscription({
        variables: {
          input: {
            email,
            audioExperiencesPath: audio.audioExperiencesPath,
            audioRoutinePath: audio.audioRoutinePath,
          },
        },
      });

      onReload();
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [loadingTexts, audio, email]);

  const onGenerateAnalysis = useCallback(() => {
    doLoadAnalysis()
      .then(onReload)
      .catch((err) => message.error(err?.message || 'Ocorreu um erro'));
  }, [onReload, doLoadAnalysis]);

  const hasTranscriptions = useMemo(() => (
    !!(
      audio.audioRoutineTranscription
      || audio.audioExperiencesTranscription
    )
  ), [audio.audioRoutineTranscription, audio.audioExperiencesTranscription]);

  return (
    <>
      <Title level={3}>
        <span style={{ paddingRight: 10 }}>Áudios</span>
        <Tooltip title="Abaixo estão as análises de áudio da pessoa candidata, realizada pela I.A. da Timbre" placement="topLeft">
          <InfoCircleFilled style={{ fontSize: 14 }} />
        </Tooltip>
      </Title>

      <Descriptions bordered title="" size="small" column={1}>
        <Descriptions.Item label="Ações">
          <div>
            <Button
              size="small"
              className="mr-2"
              loading={loadingTexts}
              onClick={onGenerateAudioTranscription}
            >
              {hasTranscriptions ? 'Regerar transcrições' : 'Gerar transcrições'}
            </Button>

            <Button
              size="small"
              className="mr-2"
              loading={loadingAnalysis}
              onClick={onGenerateAnalysis}
              disabled={!hasTranscriptions}
            >
              {hasAnalysis ? 'Regerar análise' : 'Gerar análise'}
            </Button>
          </div>

          <div />
        </Descriptions.Item>

        <Descriptions.Item label="Experiências profissionais" className="content-detail">
          {audio.audioExperiencesText && audio.audioExperiencesText}

          {(audio.audioExperiencesPath) && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio
              src={`${process.env.REACT_APP_URL_ASSETS}/${audio.audioExperiencesPath}`}
              preload="auto"
              controls
            />
          )}

          {!!audio.audioExperiencesTranscription && (
            <>
              <br />
              <br />
              <strong>Transcrição: </strong>
              {audio.audioExperiencesTranscription}
            </>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Dia a dia na função" className="content-detail">
          {audio.audioRoutineText && audio.audioRoutineText}

          {(audio.audioRoutinePath) && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <audio
              src={`${process.env.REACT_APP_URL_ASSETS}/${audio.audioRoutinePath}`}
              preload="auto"
              controls
            />
          )}

          {!!audio.audioRoutineTranscription && (
            <>
              <br />
              <br />
              <strong>Transcrição: </strong>
              {audio.audioRoutineTranscription}
            </>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default AudioDetail;
