import {
  PositionApplicationRecruiterStatusEnum,
  PositionApplicationStatusEnum,
} from '../../Graphql/generated';

export function getColor(status: string) {
  if (status === 'Reprovado') return '#7e0010';
  if (status === 'Pendente') return '#b29919';
  if (status === 'Aprovado') return 'green';
  if (status === 'Talvez') return '#869f27';
  if (status === 'Futuro potencial') return '#0082ff';
  if (status === 'Inadequado') return '#7e0010';

  return '#000000';
}

export function getStatusText(item: string) {
  const value = item.toUpperCase();

  if (value === PositionApplicationStatusEnum.Approved.toUpperCase()) return 'Aprovado';

  if (value === PositionApplicationStatusEnum.Pendent.toUpperCase()) return 'Pendente';

  if (value === PositionApplicationStatusEnum.Rejected.toUpperCase()) return 'Reprovado';

  if (value === PositionApplicationRecruiterStatusEnum.Maybe.toUpperCase()) return 'Talvez';

  if (value === PositionApplicationRecruiterStatusEnum.Future.toUpperCase()) return 'Futuro potencial';

  if (value === PositionApplicationRecruiterStatusEnum.Inappropriate.toUpperCase()) return 'Inadequado';

  return value;
}

export function getPositionStatusEnumValueByText(item: string) {
  const value = (item || '').toUpperCase();

  switch (value) {
    case PositionApplicationStatusEnum.Approved.toUpperCase():
      return PositionApplicationStatusEnum.Approved;

    case PositionApplicationStatusEnum.Pendent.toUpperCase():
      return PositionApplicationStatusEnum.Pendent;

    case PositionApplicationStatusEnum.Rejected.toUpperCase():
      return PositionApplicationStatusEnum.Rejected;

    default:
      return null;
  }
}

export function getRecruiterStatusEnumValueByText(item: string) {
  const value = (item || '').toUpperCase();

  switch (value) {
    case PositionApplicationRecruiterStatusEnum.Maybe.toUpperCase():
      return PositionApplicationRecruiterStatusEnum.Maybe;

    case PositionApplicationRecruiterStatusEnum.Future.toUpperCase():
      return PositionApplicationRecruiterStatusEnum.Future;

    case PositionApplicationRecruiterStatusEnum.Inappropriate.toUpperCase():
      return PositionApplicationRecruiterStatusEnum.Inappropriate;

    case PositionApplicationRecruiterStatusEnum.Pendent.toUpperCase():
      return PositionApplicationRecruiterStatusEnum.Pendent;

    case PositionApplicationRecruiterStatusEnum.Approved.toUpperCase():
      return PositionApplicationRecruiterStatusEnum.Approved;

    default:
      return null;
  }
}
