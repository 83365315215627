import React from 'react';

function DefaultLogo() {
  return (
    <div className="mb-4 d-flex justify-content-center align-items-center">
      <img
        src="/images/logo.png"
        style={{ maxWidth: '225px' }}
        alt="Logo"
      />
    </div>
  );
}

export default DefaultLogo;
