import React, { useCallback } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  HomeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  Button, message, Space, Table,
} from 'antd';
import { Link } from 'react-router-dom';
import { Row } from 'antd/lib';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import {
  useApplicationsQuery, useExportApplicationsDataMutation,
} from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import { formatDateLuxon } from '../../Utils/formatDateLuxon';
import { getColor } from './helpers';
import { useApplicationReview } from './useApplicationReview';

function ApplicationsGeneralList() {
  const { loading, data, refetch } = useApplicationsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [doExportData, { loading: loadingExport }] = useExportApplicationsDataMutation({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const { onDeny, onApprove, loading: loadingReview } = useApplicationReview(refetch);

  const onExportData = useCallback(async () => {
    try {
      if (loadingExport) return;

      const { data: res } = await doExportData();

      if (!res?.exportApplicationsData) {
        throw new Error('Ocorreu um erro ao exportar os dados.');
      }

      window.open(res?.exportApplicationsData, '_blank');
    } catch (err) {
      message.error(err.message);
    }
  }, [doExportData, loadingExport]);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Aplicações (Geral)' },
      ]}
    >
      <Row className="mb-3">
        <Space align="center" style={{ alignItems: 'center', display: 'flex' }}>
          <Title level={5} style={{ margin: 0 }}>Aplicações (Geral)</Title>

          <Button
            type="primary"
            htmlType="button"
            loading={loadingExport}
            onClick={onExportData}
          >
            Exportar em CSV
          </Button>
        </Space>
      </Row>

      <Table
        loading={loading || loadingReview}
        onRow={(record) => ({
          onClick: (ev) => {
            // @ts-ignore
            const target = (ev.target?.tagName || '').toLowerCase();

            if (!['a', 'svg', 'button'].includes(target)) {
              window.open(`${AppRouterEnum.CANDIDATES_PREFIX}/${record.candidateId}`);
            }
          },
        })}
        className="table-clickable-row"
        columns={[
          {
            title: 'Vaga',
            dataIndex: 'positionName',
            key: 'positionName',
            sorter: (a, b) => a.positionName.localeCompare(b.positionName),
          }, {
            title: 'Nome do candidato',
            dataIndex: 'candidateName',
            key: 'candidateName',
            sorter: (a, b) => a.candidateName.localeCompare(b.candidateName),
          }, {
            title: 'Empresa',
            dataIndex: 'empresa',
            key: 'empresa',
            sorter: (a, b) => a.companyName.localeCompare(b.companyName),
            render: (_, record) => <p className="m-0">{record.companyName}</p>,
          }, {
            title: 'Data de aplicação',
            dataIndex: 'appliedAt',
            key: 'appliedAt',
            render: (_, record) => <p className="m-0">{formatDateLuxon(record.appliedAt)}</p>,
          }, {
            title: 'Status Timbre',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (_, record) => (
              <p
                className="m-0"
                style={{ color: getColor(record.status) }}
              >
                {record.status}
              </p>
            ),
          }, {
            title: 'Status Recrutador',
            dataIndex: 'recruiterStatus',
            key: 'recruiterStatus',
            sorter: (a, b) => a.recruiterStatus.localeCompare(b.recruiterStatus),
            render: (_, record) => (
              <p
                className="m-0"
                style={{ color: getColor(record.recruiterStatus) }}
              >
                {record.recruiterStatus}
              </p>
            ),
          }, {
            title: 'Ações',
            key: 'acoes',
            render: (_, record) => (
              <Space size="middle">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={`${AppRouterEnum.CANDIDATES_PREFIX}/${record.candidateId}`}
                  title={`Visualizar candidato ${record.candidateName}`}
                  target="_blank"
                >
                  <Button icon={<UserOutlined />} shape="circle" htmlType="button" />
                </Link>

                <Link
                  to={`${process.env.REACT_APP_SITE_CANDIDATO}/vagas/${record.positionId}`}
                  title={`Visualizar vaga ${record.candidateName}`}
                  target="_blank"
                >
                  <Button icon={<InfoCircleOutlined />} shape="circle" />
                </Link>

                {record.status === 'Pendente' && (
                  <>
                    -

                    <Button
                      icon={<CheckCircleOutlined style={{ color: 'green' }} />}
                      shape="circle"
                      onClick={() => onApprove(record.id)}
                    />

                    <Button
                      icon={<CloseCircleOutlined style={{ color: 'red' }} />}
                      shape="circle"
                      onClick={() => onDeny(record.id)}
                    />
                  </>
                )}
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={data?.applications || []}
        locale={{ emptyText: 'Nenhuma aplicação encontrada' }}
      />
    </PageContainer>
  );
}

export default ApplicationsGeneralList;
