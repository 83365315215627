import React, { useMemo } from 'react';
import { Descriptions } from 'antd';
import { CandidateDetailSelfDeclarationOutput } from '../../../../Graphql/generated';
import { useBaseStore } from '../../../../Stores/useBaseStore';

interface ISelfDeclarationDetail {
  selfDeclaration: CandidateDetailSelfDeclarationOutput;
}

function SelfDeclarationDetail({ selfDeclaration }: ISelfDeclarationDetail) {
  const { getOptionValue, getQuestionTitle } = useBaseStore(['getOptionValue', 'getQuestionTitle']);

  const items = useMemo(() => ([
    {
      title: getQuestionTitle('professionalCategory'),
      value: getOptionValue('professionalCategory', selfDeclaration?.professionalCategoryId),
    },
    {
      title: getQuestionTitle('howKnowTimbre'),
      value: getOptionValue('howKnowUs', selfDeclaration?.howKnowTimbreId),
    },
    {
      title: getQuestionTitle('state'),
      value: getOptionValue('states', selfDeclaration?.stateId),
    },
    {
      title: getQuestionTitle('jobType'),
      value: selfDeclaration?.jobType || '-',
    },
    {
      title: getQuestionTitle('ageRange'),
      value: getOptionValue('ageRange', selfDeclaration?.ageRangeId),
    },
    {
      title: getQuestionTitle('educationLevel'),
      value: getOptionValue('educationLevel', selfDeclaration?.educationLevelId),
    },
    {
      title: getQuestionTitle('gender'),
      value: getOptionValue('gender', selfDeclaration?.genderId),
    },
    {
      title: getQuestionTitle('sexualOrientation'),
      value: getOptionValue('sexualOrientation', selfDeclaration?.sexualOrientationId),
    },
    {
      title: getQuestionTitle('disabledPerson'),
      value: getOptionValue('disabledPerson', selfDeclaration?.disabledPersonId),
    },
    {
      title: getQuestionTitle('race'),
      value: getOptionValue('race', selfDeclaration?.raceId),
    },
  ]), [getQuestionTitle, getOptionValue, selfDeclaration]);

  return (
    <>
      <Descriptions bordered title="Autodeclaração" size="small" column={2}>
        <Descriptions.Item label="Nome">{selfDeclaration?.firstName || '-'}</Descriptions.Item>
        <Descriptions.Item label="Sobrenome">{selfDeclaration?.lastName || '-'}</Descriptions.Item>
        {!!selfDeclaration?.socialName && (
          <Descriptions.Item label="Nome social">{selfDeclaration.socialName}</Descriptions.Item>
        )}
      </Descriptions>

      <br />

      <Descriptions bordered size="small" column={2}>
        <Descriptions.Item label="WhatsApp">{selfDeclaration?.phone || '-'}</Descriptions.Item>

        <Descriptions.Item label="LinkedIn">
          {selfDeclaration?.linkedin ? (
            <a
              href={selfDeclaration.linkedin}
              target="_blank"
              rel="nofollow noreferrer"
              title="LinkedIn"
            >
              {selfDeclaration.linkedin}
            </a>
          ) : (
            '-'
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Formação acadêmica">{selfDeclaration?.academicBackground || '-'}</Descriptions.Item>
      </Descriptions>

      <br />

      <Descriptions bordered size="small" column={1}>
        {items.map((item) => (
          <Descriptions.Item key={`key-desc-${item.title}`} label={item.title}>{item.value}</Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}

export default SelfDeclarationDetail;
