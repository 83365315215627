import { setContext } from '@apollo/client/link/context';
import { v4 } from 'uuid';

import {
  ApolloClient, from, createHttpLink, InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { message } from 'antd';
import { getLocalStorageItem, removeLocalStorageItem } from '../Utils/localStorageProvider';

export const formatErrors = (errors: GraphQLError[]): string => {
  try {
    const messages = errors.map((item) => item.message);

    return messages.join('\n');
  } catch (e) {
    return 'Ocorreu um erro.';
  }
};

const headersLink = setContext(async (_, { headers }) => {
  const Authorization = await getLocalStorageItem('Auth:Token') || '';

  return ({
    headers: {
      ...headers,
      'x-transaction-id': v4(),
      'x-api-key': process.env.REACT_APP_API_KEY,
      Authorization,
    },
  });
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_URL_GATEWAY });

export function createApolloClient() {
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    cache: new InMemoryCache(),
    link: from([
      headersLink,
      onError(({
        graphQLErrors,
        networkError,
        forward,
        operation,
      }) => {
        if (graphQLErrors) {
          // eslint-disable-next-line no-restricted-syntax
          for (const err of graphQLErrors) {
            switch (err.message) {
              case 'UNAUTHENTICATED':
                message.error('Sua autenticação expirou. Por favor, refaça o login.', 4);

                removeLocalStorageItem('Auth:Token');
                removeLocalStorageItem('Auth:Email');

                window.location.href = '/login';

                forward(operation);
                break;

              default: {
                const messages = formatErrors(graphQLErrors as GraphQLError[]);
                message.error(messages, 4);

                forward(operation);
                break;
              }
            }
          }
        }

        if (networkError && networkError.message) {
          message.error(networkError.message, 3);
        }

        forward(operation);
      }),
      httpLink,
    ]),
  });
}
