import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col, Form, Input, message, Row, Select,
} from 'antd/lib';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import PageContainer from '../../Components/Atoms/PageContainer';
import DefaultLoading from '../../Components/Atoms/DefaultLoading';
import {
  useCompaniesQuery,
  useGetRecruiterLazyQuery,
  useUpsertRecruiterMutation,
} from '../../Graphql/generated';

interface IModel {
  name: string;
  email: string;
  active: boolean;
  companyId: string;
}

function RecruiterForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [$form] = Form.useForm<IModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const isEdit = useMemo(() => id !== 'criar', [id]);

  const [model, setModel] = useState<IModel>({
    name: '',
    email: '',
    active: true,
    companyId: '',
  });

  const { data: dataCompanies, loading: loadingCompanies } = useCompaniesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [doLoadRecruiter, { loading, error }] = useGetRecruiterLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { id: id! },
  });

  const [onSaveRecruiter, { loading: loadingSave }] = useUpsertRecruiterMutation({
    notifyOnNetworkStatusChange: true,
  });

  const onLoad = useCallback(async () => {
    try {
      const { data } = await doLoadRecruiter();

      setModel({
        name: data?.recruiter.name || '',
        email: data?.recruiter.email || '',
        active: data?.recruiter.active || false,
        companyId: data?.recruiter.company.id || '',
      });

      setIsInitialized(true);
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [doLoadRecruiter]);

  const onSubmit = useCallback(async () => {
    try {
      const formValues = $form.getFieldsValue();

      await onSaveRecruiter({
        variables: {
          input: {
            id: isEdit ? id : null,
            name: formValues.name,
            email: formValues.email,
            active: formValues.active,
            companyId: formValues.companyId,
          },
        },
      });

      message.success('Recrutador salvo com sucesso.\nEle(a) já pode efetuar login no painel.');
      navigate(AppRouterEnum.RECRUITERS_PREFIX);
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [$form, id, isEdit, onSaveRecruiter, navigate]);

  useEffect(() => {
    if (isEdit) onLoad();
    else setIsInitialized(true);
  }, [isEdit]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message);
      navigate(AppRouterEnum.RECRUITERS_PREFIX);
    }
  }, [error]);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { path: AppRouterEnum.RECRUITERS_PREFIX, label: 'Recrutadores' },
        { label: 'Visualizar' },
      ]}
      loading={loading || loadingCompanies}
    >
      {loading || loadingCompanies || !isInitialized ? (
        <DefaultLoading />
      ) : (
        <Form
          form={$form}
          layout="vertical"
          initialValues={model}
          onFinish={onSubmit}
        >
          <Row className="mb-3">
            <Col xs={24} md={10}>
              <Form.Item
                name="name"
                className="mb-1"
                label="Nome"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  style={{ minWidth: 200 }}
                  placeholder="Nome do recrutador"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={1} />

            <Col xs={24} md={10}>
              <Form.Item
                name="email"
                className="mb-1"
                label="E-mail"
                rules={[{
                  required: true,
                  type: 'email',
                  message: 'Informe um e-mail válido.',
                }]}
              >
                <Input
                  size="large"
                  style={{ minWidth: 200 }}
                  placeholder="E-mail do recrutador"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={1} />

            <Col xs={24} md={2}>
              <Form.Item
                name="active"
                label="Ativo"
                className="mb-3"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={9}>
              <Form.Item
                name="companyId"
                label="Empresa"
                className="mb-3"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={false}
                  optionFilterProp="children"
                  style={{ minWidth: 300 }}
                  placeholder="Selecione"
                  size="large"
                  filterOption={(input, opt) => (
                    (opt!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  )}
                >
                  <Select.Option value="">Selecione</Select.Option>
                  {dataCompanies?.companies?.map((company) => (
                    <Select.Option value={company.id}>{company.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row justify="end">
                <Form.Item>
                  <Button type="primary" loading={loadingSave} htmlType="submit">Salvar</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </PageContainer>
  );
}

export default RecruiterForm;
