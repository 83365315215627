import { Tooltip as TooltipAnt } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CandidateDetailAudioOutput, CandidateQuery } from '../../../../Graphql/generated';
import AudioDetail from './AudioDetail';
import LogoIA from '../../../../Components/Atoms/LogoIA/LogoIA';
import {
  DivChartWrapper,
  LiChartItem,
} from './AnalysisDetail.styles';

interface IAnalysisDetail {
  audio: CandidateDetailAudioOutput;
  email: string;
  chart: CandidateQuery['candidate']['chart'];
  onReload: () => void;
}

export function AnalysisDetail({
  audio, email, chart, onReload,
}: IAnalysisDetail) {
  const hasAnalysis = Boolean(chart?.length && chart[0]?.analysis);

  return (
    <div>
      <div style={{ textAlign: 'center', margin: '-120px auto' }}>
        <LogoIA />
      </div>

      <AudioDetail audio={audio} email={email} hasAnalysis={hasAnalysis} onReload={onReload} />

      {hasAnalysis && (
        <DivChartWrapper>
          {chart?.map((item) => (
            <LiChartItem key={`chart-item-${item.key}`}>
              <TooltipAnt title={item.description} placement="topLeft">
                <h4>
                  {item.label}
                  <span className="ml-2"><InfoCircleOutlined style={{ color: '#5e6d7b' }} /></span>
                </h4>
              </TooltipAnt>

              <p>{item.analysis}</p>
            </LiChartItem>
          ))}
        </DivChartWrapper>
      )}
    </div>
  );
}
