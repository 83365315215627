import { Col, Row, Select } from 'antd/lib';
import React, { useMemo } from 'react';

import {
  ApplicationFilterInput,
} from '../../../Graphql/generated';
import { useBaseStore } from '../../../Stores/useBaseStore';

interface IFilterPositionByMinorities {
  filters: ApplicationFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<ApplicationFilterInput>>;
}

export function FilterPositionByMinorities({ filters, setFilter }: IFilterPositionByMinorities) {
  const options = useBaseStore(['options']);

  const filterOptions = useMemo(() => ([
    {
      label: 'Gênero',
      options: options.options.gender,
      value: filters.genderId,
      onSelect: (genderId: string) => {
        setFilter({ ...filters, genderId: genderId ? +genderId : undefined });
      },
    },
    {
      label: 'Cor/Raça/Etnia',
      options: options.options.race,
      value: filters.raceId,
      onSelect: (raceId: string) => {
        setFilter({ ...filters, raceId: raceId ? +raceId : undefined });
      },
    },
    {
      label: 'Orientação sexual',
      options: options.options.sexualOrientation,
      value: filters.sexualOrientationId,
      onSelect: (sexualOrientationId: string) => {
        setFilter({
          ...filters,
          sexualOrientationId: sexualOrientationId ? +sexualOrientationId : undefined,
        });
      },
    },
  ]), [filters, options]);

  return (
    <Row className="mb-3">
      {filterOptions.map((item, i) => {
        const isLast = filterOptions.length - 1 === i;

        return (
          <Col
            xs={24}
            md={24 / filterOptions.length}
            className={`${isLast ? '' : 'pr-1'} ${i === 0 ? '' : 'pl-1'}`}
          >
            <p>{item.label}</p>

            <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Selecione"
              size="middle"
              value={`${item.value || ''}`}
              onChange={item.onSelect}
            >
              <Select.Option value="">Selecione</Select.Option>
              {item.options.map((opt) => (
                <Select.Option key={`opts-${item.label}-${opt.id}`} value={opt.id}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        );
      })}
    </Row>
  );
}
