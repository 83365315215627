import React from 'react';
import { ApolloProvider } from '@apollo/client';
import LoggedOutRouter from './LoggedOutRouter';
import { createApolloClient } from './apollo';

function App() {
  return (
    <ApolloProvider client={createApolloClient()}>
      <LoggedOutRouter />
    </ApolloProvider>
  );
}

export default App;
