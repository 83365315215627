import React, { useCallback, useMemo } from 'react';
import {
  DeleteOutlined, EditOutlined, ExclamationCircleOutlined, HomeOutlined, LoginOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  Button, message, Space, Table,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Row, theme } from 'antd/lib';
import { useRecruitersQuery, useRecruiterTokenLazyQuery, useRemoveRecruiterMutation } from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import IsActiveTag from '../../Components/Atoms/IsActiveTag';

const { confirm } = Modal;

function RecruitersList() {
  const { loading, data, refetch } = useRecruitersQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [onRemoveRecruiter, { loading: loadingRemove }] = useRemoveRecruiterMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [onGetRecruiterToken, { loading: loadingToken }] = useRecruiterTokenLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const navigate = useNavigate();

  const onGetToken = useCallback(async (id: string, email: string) => {
    try {
      if (loadingToken) return;

      const { data: res } = await onGetRecruiterToken({
        variables: { id },
        fetchPolicy: 'no-cache',
      });

      if (!res?.recruiterToken) {
        throw new Error('Erro ao carregar o token do recrutador.');
      }

      const queryParams = `token=${encodeURIComponent(res.recruiterToken)}&email=${encodeURIComponent(email)}`;
      window.open(`${process.env.REACT_APP_SITE_RECRUTADOR}/login-token?${queryParams}`, '_blank');
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [loadingToken]);

  const onDelete = useCallback((id: string) => {
    try {
      confirm({
        title: 'Tem certeza que deseja excluir esse recrutador?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onRemoveRecruiter({ variables: { id } })
            .then(() => refetch());
        },
        onCancel() {},
      });
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [onRemoveRecruiter, refetch]);

  const colorPrimary = useMemo(() => (
    theme.defaultConfig?.token?.colorPrimary || '#1777ff'
  ), []);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Recrutadores' },
      ]}
    >
      <Row className="mb-3">
        <Space align="center">
          <Title level={5} style={{ margin: 0 }}>Recrutadores</Title>

          <Button
            type="primary"
            size="small"
            onClick={() => navigate(`${AppRouterEnum.RECRUITERS_PREFIX}/criar`)}
          >
            Criar novo recrutador
          </Button>
        </Space>
      </Row>

      <Table
        loading={loading || loadingRemove}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            width: 150,
          }, {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            width: 150,
          }, {
            title: 'Empresa',
            dataIndex: 'empresa',
            key: 'empresa',
            width: 150,
            render: (_, record) => (
              <p className="m-0">{record.company.name}</p>
            ),
          }, {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
              <Space size="middle">
                <IsActiveTag isActive={record.active} />
              </Space>
            ),
            width: 100,
          }, {
            title: 'Ações',
            key: 'acoes',
            width: 130,
            render: (_, record) => (
              <Space size="middle">
                <Button
                  icon={<LoginOutlined style={{ color: colorPrimary }} />}
                  shape="circle"
                  loading={loadingToken}
                  onClick={() => onGetToken(record.id, record.email)}
                />

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={`${`${AppRouterEnum.RECRUITERS_PREFIX}/${record.id}`}`}
                  title={`Visualizar ${record.name}`}
                >
                  <Button
                    icon={<EditOutlined style={{ color: colorPrimary }} />}
                    shape="circle"
                    htmlType="button"
                  />
                </Link>

                <Button
                  icon={<DeleteOutlined style={{ color: colorPrimary }} />}
                  shape="circle"
                  onClick={() => onDelete(record.id)}
                />
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={data?.recruiters || []}
        locale={{ emptyText: 'Nenhum recrutador encontrada' }}
      />
    </PageContainer>
  );
}

export default RecruitersList;
