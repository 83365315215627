import React, { useCallback } from 'react';
import {
  Form, Row, Layout, message,
} from 'antd';
import { Link } from 'react-router-dom';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import DefaultLogo from '../../Components/Atoms/DefaultLogo';
import { useAuthUserMutation } from '../../Graphql/generated';
import { useAuthenticationStore } from '../../Stores/useAuthenticationStore';

const { Content } = Layout;

function Login() {
  const { onSignIn } = useAuthenticationStore(['onSignIn']);

  const [onAuth, { loading }] = useAuthUserMutation({
    notifyOnNetworkStatusChange: true,
  });

  const doLogin = useCallback(async (res: CredentialResponse) => {
    try {
      if (!res.clientId || !res.credential) {
        throw new Error('There was an error performing your Google authentication.');
      }

      const { data } = await onAuth({
        variables: {
          input: {
            clientId: res.clientId,
            credential: res.credential,
          },
        },
      });

      if (!data?.authUser?.token || !data?.authUser?.email) {
        throw new Error('Ocorreu um erro ao efetuar o login.');
      }

      onSignIn(data?.authUser.token, data.authUser.email);
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [onAuth, loading, onSignIn]);

  return (
    <Layout className="logged-out-page">
      <Content className="bg-white">
        <Row justify="center" align="middle" className="px-1" style={{ minHeight: '100vh' }}>
          <div style={{ width: '100%', maxWidth: '350px' }} className="inner-content">
            <Link to="/" title="Timbre">
              <DefaultLogo />
            </Link>

            <Form className="p-4 radius-1" onFinish={() => {}}>
              <Form.Item className="mb-0 py-3">
                <div className="flex-row align-items-center justify-content-center">
                  <GoogleLogin
                    onSuccess={doLogin}
                    onError={() => message.error('Falha no login.')}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </Row>
      </Content>
    </Layout>
  );
}

export default Login;
