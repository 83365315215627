import { useMemo } from 'react';
import {
  CandidateDetailBehavioralV2Output,
} from '../../../../Graphql/generated';
import { useBaseStore } from '../../../../Stores/useBaseStore';

export interface IFormFields {
  title: string;
  value: string;
}

export function useBehavioralQuestions(behavioral: CandidateDetailBehavioralV2Output) {
  const { getOptionValue, getQuestionTitle } = useBaseStore([
    'getOptionValue',
    'getQuestionTitle',
  ]);

  const res = useMemo((): IFormFields[] => [
    {
      title: getQuestionTitle('feelingComfortable'),
      value: getOptionValue('likert', behavioral.feelingComfortableId),
    },
    {
      title: getQuestionTitle('checkComplexInfo'),
      value: getOptionValue('likert', behavioral.checkComplexInfoId),
    },
    {
      title: getQuestionTitle('easeNewKnowledge'),
      value: getOptionValue('likert', behavioral.easeNewKnowledgeId),
    },
    {
      title: getQuestionTitle('difficultWithNewTasks'),
      value: getOptionValue('likert', behavioral.difficultWithNewTasksId),
    },
    {
      title: getQuestionTitle('creativeSolutions'),
      value: getOptionValue('likert', behavioral.creativeSolutionsId),
    },
    {
      title: getQuestionTitle('leadingTeamsOrProjects'),
      value: getOptionValue('likert', behavioral.leadingTeamsOrProjectsId),
    },
    {
      title: getQuestionTitle('manageHighPressureSituations'),
      value: getOptionValue('likert', behavioral.manageHighPressureSituationsId),
    },
    {
      title: getQuestionTitle('understandNecessities'),
      value: getOptionValue('likert', behavioral.understandNecessitiesId),
    },
    {
      title: getQuestionTitle('comfortableNegotiating'),
      value: getOptionValue('likert', behavioral.comfortableNegotiatingId),
    },
    {
      title: getQuestionTitle('difficultAdaptCommunication'),
      value: getOptionValue('likert', behavioral.difficultAdaptCommunicationId),
    },
  ], [behavioral, getQuestionTitle, getOptionValue]);

  return res;
}
