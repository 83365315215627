import React, { useMemo } from 'react';
import { Descriptions, Tooltip as TooltipAnt } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Tooltip } from 'antd/lib';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { CandidateDetailBehavioralV2Output, CandidateQuery } from '../../../../Graphql/generated';
import { useBehavioralQuestions } from './useBehavioralQuestions';
import {
  DivBar,
  DivBarsWrapper, DivChartWrapper,
  DivVisualWrapper, LiAderenciaItem,
  LiChartItem,
} from './BehavioralV2Detail.styles';

interface IBehavioralDetail {
  behavioral: CandidateDetailBehavioralV2Output;
  chart: CandidateQuery['candidate']['chart'];
}

const tooltipText = 'A análise abaixo levou em consideração as respostas dos profissionais na etapa Comportamento. \n'
 + 'A barra "esperado" representa a pontuação máxima estipulada pela Metodologia Timbre para o nível profissional do '
 + 'candidato (Júnior, Pleno ou Sênior). \n A barra "Candidato" representa a pontuação alcançada pelo profissional inscrito';

function BehavioralDetail({ behavioral, chart }: IBehavioralDetail) {
  const items = useBehavioralQuestions(behavioral);

  const totalPercent = useMemo(() => {
    if (!chart?.length) return 0;

    const sumCandidateScores = chart?.reduce((acc, item) => acc + item.candidatePercentual, 0);
    const sumExpectedScores = chart?.reduce((acc, item) => acc + item.expectedPercentual, 0);

    return ((sumCandidateScores / sumExpectedScores) * 100).toFixed(0);
  }, [chart]);

  const behavioralRange = useMemo(() => [
    [totalPercent <= 99, 'Não atende às expectativas'],
    [totalPercent <= 129, 'Atende às expectativas'],
    [totalPercent <= 159, 'Acima das expectativas'],
    [totalPercent <= 189, 'Supera todas as expectativas'],
    [totalPercent >= 190, 'Desempenho excepcional'],
  ], [totalPercent]);

  const behavioralResult = useMemo(() => (
    behavioralRange.findIndex(([condition]) => condition)
  ), [totalPercent]);

  return (
    <>
      <Title level={3}>
        <span style={{ paddingRight: 10 }}>Questionário comportamental</span>
        <Tooltip title={tooltipText} placement="topLeft">
          <InfoCircleFilled style={{ fontSize: 14 }} />
        </Tooltip>
      </Title>

      <Descriptions bordered size="small" column={1} style={{ marginBottom: 20 }}>
        {items.map((item) => (
          <Descriptions.Item key={`analise-desc-${item.title}`} label={item.title}>
            <span style={{ whiteSpace: 'nowrap' }}>{item.value}</span>
          </Descriptions.Item>
        ))}
      </Descriptions>

      <DivChartWrapper>
        {chart?.map((item) => (
          <LiChartItem key={`item-${item.key}`}>
            <TooltipAnt title={item.description} placement="topLeft">
              <h4>
                {item.label}
                <span className="ml-2"><InfoCircleOutlined style={{ color: '#5e6d7b' }} /></span>
              </h4>
            </TooltipAnt>

            <DivVisualWrapper>
              <DivBarsWrapper>
                <div>
                  <DivBar className="bar">
                    <div className="fill" style={{ width: `${item.expectedPercentual * 100}%` }} />
                  </DivBar>
                </div>

                <div>
                  <DivBar className="bar -pink">
                    <div className="fill" style={{ width: `${item.candidatePercentual * 100}%` }} />
                  </DivBar>
                </div>
              </DivBarsWrapper>

              <div>
                <p>Esperado</p>
                <p>Candidato</p>
              </div>
            </DivVisualWrapper>
          </LiChartItem>
        ))}

        <LiAderenciaItem className="-aderencia">
          <h4>Aderência comportamental do candidato</h4>

          <p>
            O resultado abaixo representa o grau de aderência da pessoa candidata ao nível
            profissional (Júnior, Pleno ou Sênior) indicado por ela.
            <br />
            <br />
            É importante observar que na medida que a intersecção de diversidade é configurada,
            a aderência pode ser impactada.
          </p>

          <br />
          <p><span>{`${behavioralRange[behavioralResult][1]}`}</span></p>
          <br />
          <br />
          <div style={{ display: 'flex', gap: '10px' }}>
            {new Array(5).fill(true).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`behavioralResult-${i}`} style={{ flex: 1 }}>
                <DivBar className={`base-class ${behavioralResult === i ? 'bar -pink' : ''}`}>
                  <div className="fill" style={{ width: '100%' }} />
                </DivBar>
              </div>
            ))}
          </div>
        </LiAderenciaItem>
      </DivChartWrapper>
    </>
  );
}

export default BehavioralDetail;
