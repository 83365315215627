import React from 'react';
import { Descriptions } from 'antd';
import { Col, Row } from 'antd/lib';
import Star from '../../../../Components/Atoms/Star';

interface ISkillsDetail {
  techs: { id: string; name: string; rating: number; }[];
  languages: { id: string; name: string; rating: number; }[];
}

function SkillsDetail({ techs, languages }: ISkillsDetail) {
  return (
    <Row>
      <Col xs={24} md={12}>
        <Descriptions bordered title="Habilidades técnicas" size="small" column={1}>
          {techs.map((tech) => (
            <Descriptions.Item label={tech.name} key={`desc-item-tech-${tech.name}`}>
              {new Array(tech.rating).fill(0).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Star key={`tech-${tech.name}-${i}`} checked />
              ))}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Col>

      <Col xs={24} md={12}>
        <Descriptions bordered title="Idiomas" size="small" column={1}>
          {languages.map((language) => (
            <Descriptions.Item label={language.name} key={`desc-item-lang-${language.name}`}>
              {new Array(language.rating).fill(0).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
                <Star key={`lang-${language.name}-${i}`} checked />
              ))}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Col>
    </Row>
  );
}

export default SkillsDetail;
