import { Col, Row, Select } from 'antd/lib';
import React from 'react';
import {
  getPositionStatusEnumValueByText,
  getRecruiterStatusEnumValueByText,
  getStatusText,
} from '../helpers';
import {
  ApplicationFilterInput,
  PositionApplicationRecruiterStatusEnum,
  PositionApplicationStatusEnum,
} from '../../../Graphql/generated';

interface IFilterPositionByStatus {
  filters: ApplicationFilterInput;
  setFilter: React.Dispatch<React.SetStateAction<ApplicationFilterInput>>;
}

export function FilterPositionByStatus({ filters, setFilter }: IFilterPositionByStatus) {
  return (
    <Row className="mb-3">
      <Col xs={24} md={12} className="pr-1">
        <p>Status da aplicação:</p>

        <Select
          allowClear
          style={{ minWidth: '100%' }}
          placeholder="Selecione"
          size="middle"
          value={filters.applicationStatus}
          onChange={(val) => {
            setFilter((v) => ({
              ...v,
              applicationStatus: getPositionStatusEnumValueByText(val),
            }));
          }}
        >
          <Select.Option value="">Selecione</Select.Option>
          {Object.keys(PositionApplicationStatusEnum).map((k) => (
            <Select.Option key={`app-status-${k}`} value={k}>
              {getStatusText(k)}
            </Select.Option>
          ))}
        </Select>
      </Col>

      <Col xs={24} md={12} className="pl-1">
        <p>Status do recrutador:</p>

        <Select
          allowClear
          style={{ minWidth: '100%' }}
          placeholder="Selecione"
          size="middle"
          value={filters.recruiterStatus}
          onChange={(val) => {
            setFilter((v) => ({
              ...v,
              recruiterStatus: getRecruiterStatusEnumValueByText(val),
            }));
          }}
        >
          <Select.Option value="">Selecione</Select.Option>
          {Object.keys(PositionApplicationRecruiterStatusEnum).map((k) => (
            <Select.Option key={`app-status-${k}`} value={k}>
              {getStatusText(k)}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
