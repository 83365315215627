import React, { useCallback, useMemo, useState } from 'react';
import { EyeOutlined, HomeOutlined, LoginOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  Button, Space, Table, TablePaginationConfig,
} from 'antd';
import { Link } from 'react-router-dom';
import {
  Col, Input, message, Row, theme,
} from 'antd/lib';
import {
  useCandidateListQuery, useCandidateTokenLazyQuery,
  useExportDataMutation,
} from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import IsActiveTag from '../../Components/Atoms/IsActiveTag';
import { formatDateLuxon } from '../../Utils/formatDateLuxon';

const DEFAULT_PAGE_SIZE = 25;

function BlogList() {
  const [queryOptions, setQueryOptions] = useState({
    page: 1,
    perPage: DEFAULT_PAGE_SIZE,
    searchFor: '',
  });

  const [preFilterValue, setPreFilterValue] = useState('');

  const { loading, data } = useCandidateListQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        page: queryOptions.page,
        perPage: queryOptions.perPage,
      },
      filter: {
        searchFor: queryOptions.searchFor,
      },
    },
  });

  const [onGetCandidateToken, { loading: loadingToken }] = useCandidateTokenLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [doExportData, { loading: loadingExport }] = useExportDataMutation({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const handleTableChange = useCallback((paginationOpts: TablePaginationConfig) => {
    setQueryOptions((current) => ({
      ...current,
      page: paginationOpts.current || 1,
      perPage: paginationOpts.pageSize || DEFAULT_PAGE_SIZE,
    }));
  }, []);

  const onExportData = useCallback(async () => {
    try {
      if (loadingExport) return;

      const { data: res } = await doExportData();

      if (!res?.exportData) {
        throw new Error('Ocorreu um erro ao exportar os dados.');
      }

      window.open(res?.exportData, '_blank');
    } catch (err) {
      message.error(err.message);
    }
  }, [doExportData, loadingExport]);

  const onGetToken = useCallback(async (id: string) => {
    try {
      if (loadingToken) return;

      const { data: res } = await onGetCandidateToken({
        variables: { id },
        fetchPolicy: 'no-cache',
      });

      if (!res?.candidateToken) {
        throw new Error('Erro ao carregar o token do recrutador.');
      }

      const queryParams = `token=${encodeURIComponent(res.candidateToken)}`;
      window.open(`${process.env.REACT_APP_SITE_CANDIDATO}/login-token?${queryParams}`, '_blank');
    } catch (err) {
      message.error(err?.message || 'Ocorreu um erro');
    }
  }, [loadingToken]);

  const colorPrimary = useMemo(() => (
    theme.defaultConfig?.token?.colorPrimary || '#1777ff'
  ), []);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Candidatos' },
      ]}
    >
      <Row className="mb-4" style={{ justifyContent: 'space-between' }}>
        <Space align="center" style={{ alignItems: 'center', display: 'flex' }}>
          <Title level={5} className="m-0">Candidatos</Title>

          <Button
            type="primary"
            htmlType="button"
            loading={loadingExport}
            onClick={onExportData}
          >
            Exportar em CSV
          </Button>
        </Space>

        <Col>
          <Space align="center">
            <p>Pesquisar:</p>

            <Input
              size="middle"
              value={preFilterValue}
              style={{ minWidth: 200 }}
              placeholder="Busque por nome ou e-mail"
              onChange={(e) => setPreFilterValue(e.target.value)}
            />

            <Button
              type="primary"
              htmlType="button"
              size="middle"
              loading={loading}
              onClick={() => {
                setQueryOptions((current) => ({
                  ...current,
                  page: 1,
                  perPage: DEFAULT_PAGE_SIZE,
                  searchFor: preFilterValue,
                }));
              }}
            >
              Buscar
            </Button>

            <Button
              type="dashed"
              htmlType="button"
              size="middle"
              loading={loading}
              disabled={!queryOptions.searchFor}
              onClick={() => {
                setPreFilterValue('');
                setQueryOptions((current) => ({
                  ...current,
                  page: 1,
                  perPage: DEFAULT_PAGE_SIZE,
                  searchFor: '',
                }));
              }}
            >
              Limpar
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        loading={loading}
        columns={[
          {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email-1',
          }, {
            title: 'Autodeclaração',
            dataIndex: 'selfDeclarationFilled',
            key: 'selfDeclarationFilled-2',
            render: (_, record) => (
              <Space size="middle">
                <IsActiveTag isActive={record.selfDeclarationFilled} />
              </Space>
            ),
            width: 200,
          }, {
            title: 'Comportamento V2',
            dataIndex: 'behavioralV2Filled',
            key: 'behavioralV2Filled-3',
            render: (_, record) => (
              <Space size="middle">
                <IsActiveTag isActive={record.behavioralV2Filled} />
              </Space>
            ),
            width: 190,
          }, {
            title: 'Áudios',
            dataIndex: 'audioFilled',
            key: 'audioFilled-4',
            render: (_, record) => (
              <Space size="middle">
                <IsActiveTag isActive={record.audioFilled} />
              </Space>
            ),
            width: 140,
          }, {
            title: 'Criado em',
            dataIndex: 'createdAt',
            key: 'createdAt-4',
            render: (_, record) => (
              <Space size="middle">
                <p style={{ whiteSpace: 'nowrap' }}>{formatDateLuxon(record.createdAt)}</p>
              </Space>
            ),
          }, {
            title: 'Ações',
            key: 'acoes-5',
            width: 130,
            render: (_, record) => (
              <Space size="middle">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={`${`${AppRouterEnum.CANDIDATES_PREFIX}/${record.id}`}`}
                  title={`Visualizar ${record.email}`}
                >
                  <Button icon={<EyeOutlined />} shape="circle" htmlType="button" />
                </Link>

                <Button
                  icon={<LoginOutlined style={{ color: colorPrimary }} />}
                  shape="circle"
                  loading={loadingToken}
                  onClick={() => onGetToken(record.id)}
                />
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={data?.candidateList?.items || []}
        pagination={{
          pageSize: queryOptions.perPage,
          defaultCurrent: queryOptions.page,
          total: (data?.candidateList?.count || 0),
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
        onChange={handleTableChange}
        locale={{ emptyText: 'Nenhum candidato encontrado' }}
      />
    </PageContainer>
  );
}

export default BlogList;
