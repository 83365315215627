import React from 'react';
import { EditOutlined, HomeOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import {
  Button, Space, Table,
} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Row } from 'antd/lib';
import { useCompaniesQuery } from '../../Graphql/generated';
import PageContainer from '../../Components/Atoms/PageContainer';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import IsActiveTag from '../../Components/Atoms/IsActiveTag';

function CompaniesList() {
  const { loading, data } = useCompaniesQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const navigate = useNavigate();

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { label: 'Empresas' },
      ]}
    >
      <Row className="mb-3">
        <Space align="center">
          <Title level={5} style={{ margin: 0 }}>Empresas</Title>

          <Button
            type="primary"
            size="small"
            onClick={() => navigate(`${AppRouterEnum.COMPANIES_PREFIX}/criar`)}
          >
            Criar nova empresa
          </Button>
        </Space>
      </Row>

      <Table
        loading={loading}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 300,
          }, {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            width: 150,
          }, {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
              <Space size="middle">
                <IsActiveTag isActive={record.active} />
              </Space>
            ),
            width: 100,
          }, {
            title: 'Ações',
            key: 'acoes',
            width: 130,
            render: (_, record) => (
              <Space size="middle">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to={`${AppRouterEnum.COMPANIES_PREFIX}/${record.id}`}
                  title={`Visualizar ${record.name}`}
                >
                  <Button icon={<EditOutlined />} shape="circle" htmlType="button" />
                </Link>
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={data?.companies || []}
        locale={{ emptyText: 'Nenhuma empresa encontrada' }}
      />
    </PageContainer>
  );
}

export default CompaniesList;
